"use client";

import { useState, useEffect } from "react";
import Link from "next/link";
import Image from "next/image";
import { fetchErrorLoading, fetchLogoImg, fetchMenuItem } from "./utils/servers/fetchData";
import IconRenderer from "./utils/libs/IconRenderer";
import Header from "./components/Header";

const ErrorPage = () => {
  const [errorData, setErrorData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [logo, setLogo] = useState(null);
  const [menu, setMenu] = useState(null);

  useEffect(() => {
    const loadErrorData = async () => {
      try {
        const data = await fetchErrorLoading();
        const menuData = await fetchMenuItem();
        const logo = await fetchLogoImg();
        setErrorData(data);
        setMenu(menuData);
        setLogo(logo[0]);
        setIsLoading(false);
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    };

    loadErrorData();
  }, []);

  const renderText = (text) => {
    if (!text) return null;
    return text.split(/(<br\s*\/?>)/).map((line, index) => {
      if (line.match(/<br\s*\/?>/)) {
        return <br key={index} />;
      }
      return <span key={index}>{line}</span>;
    });
  };

  if (isLoading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!errorData || errorData.length === 0) {
    return null;
  }

  const firstErrorData = errorData[0];

  return (
    <>
      <Header
        data={menu}
        logo={logo}
      />
      <div className="text-center flex items-center justify-center h-screen">
        <div>
          <p className="text-3xl font-bold text-[#707070]">
            {renderText(firstErrorData?.title_text)}
          </p>
          <p className="text-2xl">{renderText(firstErrorData?.text_desc)}</p>
          {firstErrorData?.error_img && (
            <Image
              src={`${process.env.NEXT_PUBLIC_POST_URL}/api/uploads/${firstErrorData.error_img}`}
              alt="error page"
              width={200}
              height={200}
              priority={false}
              className="m-auto my-4"
            />
          )}
          <Link
            href="/"
            className="flex flex-col items-center"
          >
            {firstErrorData?.icon_name && (
              <IconRenderer
                iconName={firstErrorData.icon_name}
                className="text-3xl my-3 whatsapp_icon_social"
              />
            )}
            <p className="text-xl">
              {renderText(firstErrorData?.message_forword)}
            </p>
          </Link>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
